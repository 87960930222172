<script setup>
import AppRoute from 'src/router/app-route.js';
import { Route } from 'src/router/routes.js';
import { computed } from 'vue';

const props = defineProps({
    inverted: {
        type: Boolean,
        default: false
    },
    openInNewTab: {
        type: Boolean,
        default: false
    }
});

const routerLinkAttrs = computed(() => {
    return props.openInNewTab ? { target: '_blank' } : {};
});
</script>

<template>
    <div :class="[ inverted ? null : 'text-white' ]" class="app-terms q-mb-sm q-mt-lg q-px-md">
        <p class="terms text-center">
            By&nbsp;using&nbsp;this&nbsp;service,<wbr/>you&nbsp;accept&nbsp;our<br/>
            <router-link v-bind="routerLinkAttrs" :to="AppRoute.build(Route.TermsOfService)">Terms&nbsp;of&nbsp;Service</router-link> and
            <router-link v-bind="routerLinkAttrs" :to="AppRoute.build(Route.PrivacyPolicy)">Privacy&nbsp;Policy</router-link>.
        </p>
    </div>
</template>
